import { Grid } from '@mui/material'
import React from 'react'
import Footer from '../footer'
import UserTabs from '../navigation/tabs'
import UserHeader from '../navigation/userheader'

export default function Home() {
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>  <UserHeader /></Grid>
        <Grid item xs={12}>
          <UserTabs />
        </Grid>

      </Grid>


    </div>
  )
}
