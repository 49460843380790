import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({


    headerStyle: {
        backgroundColor: '#fff !important',
        boxShadow: 'none !important',
        color: '#000 !important'
    },
    searchInput: {
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 500,
        backgroundColor: '#e5dfdf75 !important',
        boxShadow: 'none !important'
    },
    logo: {
        mr: 2, '&:hover': {
            backgroundColor: 'rgba(0 0 0 /0%) !important'
        }
    },
    arrow: {
        position: 'absolute',
        fontSize:' 7px',
        width: '3em',
        height: '3em',
      '&::before':{
          content: '""',
          margin: 'auto',
          display: 'block',
          width: 0,
          height: 0,
          borderStyle: 'solid',
        }
      },
      mypic:{
        height: "380px",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        backgroundSize: "90% auto !important",
        justifyContent: "center",
        backgroundPosition: "center !important",
      },
      mypic2:{
        height: "150px",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        backgroundSize: "90% auto !important",
        justifyContent: "center",
        backgroundPosition: "center !important",
      }


}));

export default useStyles;
