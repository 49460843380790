import React, { useEffect, useContext, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import swal from "sweetalert";
import UserHeader from "../navigation/userheader";
import axios from "axios";
import Swal from "sweetalert2";
// import { Web3Context } from "../marketPlace/providers/Web3Provider";
import MarketAbi from "../navigation/contracts/Marketplace.json";
import { ethers } from "ethers";
import Web3 from "web3";

export default function Dashboard() {
  //https://nba-players.herokuapp.com/players-stats
  // const { nftContract, marketplaceContract, hasWeb3 } = useContext(Web3Context);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };
  const [playerData, setPlayerData] = useState([]);
  useEffect(() => {
    loadWeb3();
    let userAddress = localStorage.getItem("wallet_address");
    const fetchData = async () => {
      let dom = [];
      const accounts = await window.web3.eth.getAccounts();
        console.log(accounts[0]);
      await axios
        .get(
          `${process.env.REACT_APP_Backend_url}/auth/getNftByAddr?wallet_address=${accounts[0]}`
        )
        .then((res) => {
          let listed_person = res?.data?.data;
          console.log(listed_person);

          for (let i = 0; i < listed_person.length; i++) {
            let k = listed_person[i];
            // if(k.wallet_address === accounts[0]){
              dom.push(k);
              console.log(accounts[0]);
            // }

            // for (let j = 0; j < k.length; j++) {
            //   dom.push(k[j]);
            // }
          }
          console.log(dom);

          // listed_person
          //     .filter((e) => e.status === "Accepted")
          //     .map((e, index) => {
          //        // set_chat_timer(e?.chat_end_time);

          //     });
        });

      // const response = await fetch('https://nba-players.herokuapp.com/players-stats')
      // const nbaData = await response.json()
      // console.log(nbaData)
      setPlayerData(dom.slice(0, 20));
    };
    fetchData();
  }, []);

  // async function buyNft(nft) {
  //   const price = ethers.utils.parseUnits(nft.price.toString(), "ether");
  //   const transaction = await marketplaceContract.createMarketSale(
  //     nftContract.address,
  //     nft.marketItemId,
  //     {
  //       value: price,
  //     }
  //   );
  //   await transaction.wait();
  // }

  // async function cancelNft(nft) {
  //   const transaction = await marketplaceContract.cancelMarketItem(
  //     nftContract.address,
  //     nft.marketItemId
  //   );
  //   await transaction.wait();
  // }

  // async function approveNft(nft) {
  //   const approveTx = await nftContract.approve(
  //     marketplaceContract.address,
  //     nft.tokenId
  //   );
  //   await approveTx.wait();
  //   return approveTx;
  // }

  const sell = (k) => {
    Swal.fire({
      title: "Sell NFT",
      html: `<input type="number" id="price" class="swal2-input" placeholder="Price">`,
      confirmButtonText: "Sell",
      focusConfirm: false,
      preConfirm: () => {
        const price = Swal.getPopup().querySelector("#price").value;
        if (!price) {
          Swal.showValidationMessage(`Please enter the price`);
        }

        return { Price: price };
      },
    }).then(async (result) => {
      let nft = playerData[k];
      console.log(result.value.Price);
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      // const accounts = await window.web3.eth.getAccounts();

      console.log(accounts[0]);
      const cryptoBoysContract = new window.web3.eth.Contract(
        MarketAbi.abi,
        `${process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS_MUMBAI}`
      );
      console.log(cryptoBoysContract);
      let marketId = await cryptoBoysContract.methods
              .getCurrentMarketId()
              .call() ;
      let price = result.value.Price;
      console.log(price);
      try {
        const priceInWei = ethers.utils.parseUnits(price.toString(), "ether");
        console.log(priceInWei);
        cryptoBoysContract.methods
          .createMarketItem(
            `${process.env.REACT_APP_NFT_CONTRACT_ADDRESS_MUMBAI}`,
            nft.token_id,
            priceInWei
          )
          .send({ from: accounts[0] })
          .on("transactionHash", async (res) => {
            console.log(res);

            let marketId = await cryptoBoysContract.methods
              .getCurrentMarketId()
              .call();
            let postdata = {
              wallet_address: nft.wallet_address,
              transaction_id: nft.transaction_id,
              amount: result.value.Price,
              marketId:  parseInt(marketId) +1 ,
              sell_transaction_id: res,
            };
            await axios.post(
              `${process.env.REACT_APP_Backend_url}/auth/sellNft`,
              postdata
            );
            swal("Success", "Listed in Marketplace " + res, "success").then(
              setTimeout(function () {
                window.location.reload();
              }, 2000)
            );
          });
      } catch (err) {
        console.log(err);
      }
    });

  };
  return (
    <Container fluid>
      <UserHeader />

      <br />
      <Row>
        {playerData.map((playerData, k) => (
          <Col key={k} xs={12} md={4} lg={3}>
            <Card>
              <Card.Img className="nftImgSize" src={playerData.image} />
              <Card.Body>
                <Card.Title>{playerData.nft_name}</Card.Title>
                <Card.Text className="address">
                  Trx:{" "}
                  <a
                    href={`https://mumbai.polygonscan.com/tx/${playerData.transaction_id}`}
                  >
                    {playerData.transaction_id}
                  </a>
                </Card.Text>
                <Card.Text>
                  <h6>Approval:</h6>{" "}
                  {playerData.status_of_approval === "Pending" ? (
                    <Card.Text className="YellowPending">
                      {playerData.status_of_approval}
                    </Card.Text>
                  ) : (
                    <Card.Text
                      className={
                        playerData.status_of_approval === "Accepted"
                          ? "greenAccepted"
                          : "redRejected"
                      }
                    >
                      {playerData.status_of_approval}
                    </Card.Text>
                  )}
                </Card.Text>
                <Card.Text>
                  {playerData.status_of_approval === "Accepted" ? (
                    playerData.status_of_nft === "Listed" ? (
                      <>Listed in Marketplace</>
                    ) : ( playerData.status_of_nft === "Sold"? 
                     ( <>Sold</>):(<h6 className="YellowPending" onClick={() => sell(k)}>
                     Sell 
                   </h6>)
                    )
                  ) : (
                    <></>
                  )}
                </Card.Text>
              </Card.Body>
              <br />
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
