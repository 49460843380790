import { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import UserHeader from "../navigation/userheader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
export default function Dynamic() {
  //https://nba-players.herokuapp.com/players-stats
  const success = (value) => {
    toast.success(value, { theme: "colored" });
  };
  const faild = (value) => {
    toast.error(value, { theme: "colored" });
  };

  const [playerData, setPlayerData] = useState([]);
  const accept = async (k) => {
    console.log("ddd");
    try {
      let data = {
        wallet_address: playerData[k].wallet_address,
        transaction_id: playerData[k].transaction_id,
      };
      await axios
        .post(`${process.env.REACT_APP_Backend_url}/auth/acceptNft`, data)
        .then((res) => {
          if (res.data.message === "Accepted Successfully") {
            success(res.data.message);
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          } else {
            faild(res.data.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const reject = async (k) => {
    try {
      let data = {
        wallet_address: playerData[k].wallet_address,
        transaction_id: playerData[k].transaction_id,
      };
      await axios
        .post(`${process.env.REACT_APP_Backend_url}/auth/rejectNft`, data)
        .then((res) => {
          if (res.data.message === "Rejected Successfully") {
            success(res.data.message);
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          } else {
            faild(res.data.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      let dom = [];
      await axios
        .get(`${process.env.REACT_APP_Backend_url}/auth/getList?status=Pending`)
        .then((res) => {
          let listed_person = res?.data?.data;
          console.log(listed_person);
          for (let i = 0; i < listed_person.length; i++) {
            let k = listed_person[i];
            console.log(k);
            if (k.status_of_approval === "Pending") {
              dom.push(k);
            }
          }
          console.log(dom);
        });

      setPlayerData(dom.slice(0, 15));
    };
    fetchData();
  }, []);

  return (
    <Container fluid>
      <ToastContainer />
      <UserHeader />
      <br />
      <br />
      <Row>
        {playerData.map((playerData, k) => (
          <Col key={k} xs={12} md={4} lg={3}>
            <Card>
              <Card.Img className="nftImgSize" src={playerData.image} />
              <Card.Body>
                <Card.Title>{playerData.nft_name}</Card.Title>
                <Card.Text className="address">
                  Wallet:{" "}
                  <a
                    href={`https://mumbai.polygonscan.com/address/${playerData.wallet_address}`}
                  >
                    {playerData.wallet_address}
                  </a>
                </Card.Text>
                <Card.Text className="address">
                  Trx:{" "}
                  <a
                    href={`https://mumbai.polygonscan.com/tx/${playerData.transaction_id}`}
                  >
                    {playerData.transaction_id}
                  </a>
                </Card.Text>
                <button className="red-no" onClick={() => reject(k)}>
                  Reject{" "}
                </button>{" "}
                <button onClick={() => accept(k)} className="green-yes">
                  Accept{" "}
                </button>
              </Card.Body>
              <br />
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
