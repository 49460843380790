import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import React from 'react'
import { ReactComponent as CloseIcon } from '../assets/icons/Close.svg';
import { ReactComponent as Metamask } from '../assets/icons/metamask.svg';
export default function CommonModal(props) {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState();
    const openModal = () => {
        setIsOpen(true);
    }

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    const closeModal = () => {
        setIsOpen(false);
    }
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.open}
            onClick={()=>props.closeModal()}
        >
            <Dialog
                open={props.open}
                keepMounted
                onClose={()=>props.closeModal()}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'center',ml:10 }}>
                    <Grid container alignItems="center">
                        <Grid item xs={9} display='flex' justifyContent={'center'}>
                            <Typography color='primary' variant="div">Connect your wallet</Typography>
                        </Grid>
                        <Grid item xs={3} display='flex' justifyContent={'end'}>
                            <IconButton>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container alignItems="center">
                        <Grid item xs={12} display='flex' justifyContent={'center'}>
                            <DialogContentText id="alert-dialog-slide-description">
                                Please connect your wallet before creating an NFT
                            </DialogContentText>
                            </Grid>
                            <Grid item xs={12} display='flex' justifyContent={'center'}>
                            <Button variant='contained' color='primary'startIcon={<Metamask />} sx={{ backgroundColor: '#8353fb', mt: 3, alignSelf: 'center' }} >Connect MetaMask</Button>
                        </Grid>
                    </Grid>
                </DialogContent>

            </Dialog>
        </Backdrop>
    )
}
