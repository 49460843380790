export const CATEGORY = [
	{
		value: 'Select One',
		label: 'Select One',
	},
	{
		value: 'Badges',
		label: 'Badges',
	},
	{
		value: 'Frames',
		label: 'Frames',
	},
	// {
	// 	value: 'ratherNotSay',
	// 	label: 'Rather Not Say',
	// },
];

export const MARKETDATA = [
	{
		id:1,
		value: 'MARKETPLACE',
		label: 'MARKETPLACE',
		nestedDropDowns:[
			{
				id:1,
				value: 'KNOCK2LIVE',
				label: 'KNOCK2LIVE',
				datas:[
					{
						id: 1,
						value: 'Badge',
						label: 'Badge',
					}]
			},
			{
				id:2,
				value: 'EXPLORE',
				label: 'EXPLORE',
				datas:[
					{
						id: 1,
						value: 'Badge',
						label: 'Badge',
					}]
			},
			{
				id:3,
				value: 'STATS',
				label: 'STATS',
				datas:[
					{
						id: 1,
						value: 'Badge',
						label: 'Badge',
					}]
			},
			{
				id:4,
				value: 'CREATE',
				label: 'CREATE',
				datas:[
					{
						id: 1,
						value: 'Badge',
						label: 'Badge',
					}]
			},
			{
				id:5,
				value: 'FILTER',
				label: 'FILTER',
				datas:[
					{
						id: 1,
						value: 'Badge',
						label: 'Badge',
					}]
			},
		]
	},
	{
		id:2,
		value: 'IN-APP SHOP',
		label: 'IN-APP SHOP',
		nestedDropDowns:[
			{
				id:1,
				value: 'KNOCK2LIVE',
				label: 'KNOCK2LIVE',
				datas:[
					{
						id: 1,
						value: 'Badge',
						label: 'Badge',
						data:[
							{
								id: 1,
								value: 'Animated(100)',
								label: 'Animated(100)',
							},
							{
								id: 2,
								value: 'K2(100)',
								label: 'K2(100)',
							},
							{
								id: 3,
								value: 'KOL(100)',
								label: 'KOL(100)',
							},
							{
								id: 4,
								value: 'Static(100)',
								label: 'Static(100)',
							},
						]
					},
					{
						id: 2,
						value: 'Baggage Gift(100)',
						label: 'Baggage Gift(100)',
						data:[]
					},
					{
						id: 3,
						value: 'Chat Background(100)',
						label: 'Chat Background(100)',
						data:[]
					},
					{
						id: 4,
						value: 'Chat Font(100)',
						label: 'Chat Font(100)',
						data:[]
					},
					{
						id: 5,
						value: 'Entry Animation(100)',
						label: 'Entry Animation(100)',
						data:[]
					},
					{
						id: 6,
						value: 'Profile Animation(100)',
						label: 'Profile Animation(100)',
						data:[]
					},
					{
						id: 7,
						value: 'Live Profile Animation(100)',
						label: 'Live Profile Animation(100)',
						data:[]
					},
					{
						id: 8,
						value: 'Rarity',
						label: 'Rarity',
						data:[
							{
								id: 1,
								value: 'Legendry(100)',
								label: 'Legendry(100)',
							},
							{
								id: 2,
								value: 'Epic(100)',
								label: 'Epic(100)',
							},
							{
								id: 3,
								value: 'Rare(100)',
								label: 'Rare(100)',
							},
							{
								id: 4,
								value: 'Common(100)',
								label: 'Common(100)',
							},
						]
					},
				]
			},
			{
				id:2,
				value: 'OTHERS',
				label: 'OTHERS',
				datas:[
					{
						id: 1,
						value: 'Badge',
						label: 'Badge',
					},
					{
						id: 2,
						value: 'Baggage Gift(100)',
						label: 'Baggage Gift(100)',
					},
					{
						id: 3,
						value: 'Chat Background(100)',
						label: 'Chat Background(100)',
					},
					{
						id: 4,
						value: 'Chat Font(100)',
						label: 'Chat Font(100)',
					},
					{
						id: 5,
						value: 'Entry Animation(100)',
						label: 'Entry Animation(100)',
					},
					{
						id: 6,
						value: 'Profile Animation(100)',
						label: 'Profile Animation(100)',
					},
					{
						id: 7,
						value: 'Live Profile Animation(100)',
						label: 'Live Profile Animation(100)',
					},
					{
						id: 8,
						value: 'Rarity',
						label: 'Rarity',
					},
				]
			},
		]
	},
];
export const INAPPDATA = [
	{
		value: 'KNOCK2LIVE',
		label: 'KNOCK2LIVE',
	},
	{
		value: 'OTHERS',
		label: 'OTHERS',
	},
];

export const KNOCK2LIVEDATA = [
	{
		id: 1,
		value: 'Badge',
		label: 'Badge',
	},
	{
		id: 2,
		value: 'Baggage Gift(100)',
		label: 'Baggage Gift(100)',
	},
	{
		id: 3,
		value: 'Chat Background(100)',
		label: 'Chat Background(100)',
	},
	{
		id: 4,
		value: 'Chat Font(100)',
		label: 'Chat Font(100)',
	},
	{
		id: 5,
		value: 'Entry Animation(100)',
		label: 'Entry Animation(100)',
	},
	{
		id: 6,
		value: 'Profile Animation(100)',
		label: 'Profile Animation(100)',
	},
	{
		id: 7,
		value: 'Live Profile Animation(100)',
		label: 'Live Profile Animation(100)',
	},
	{
		id: 8,
		value: 'Rarity',
		label: 'Rarity',
	},
];

export const OTHERSDATA = [
	{
		id: 1,
		value: 'Badge',
		label: 'Badge',
	},
	{
		id: 2,
		value: 'Baggage Gift(100)',
		label: 'Baggage Gift(100)',
	},
	{
		id: 3,
		value: 'Chat Background(100)',
		label: 'Chat Background(100)',
	},
	{
		id: 4,
		value: 'Chat Font(100)',
		label: 'Chat Font(100)',
	},
	{
		id: 5,
		value: 'Entry Animation(100)',
		label: 'Entry Animation(100)',
	},
	{
		id: 6,
		value: 'Profile Animation(100)',
		label: 'Profile Animation(100)',
	},
	{
		id: 7,
		value: 'Live Profile Animation(100)',
		label: 'Live Profile Animation(100)',
	},
	{
		id: 8,
		value: 'Rarity',
		label: 'Rarity',
	},
];

export const BADGEDATA = [
	{
		id: 1,
		value: 'Animated(100)',
		label: 'Animated(100)',
	},
	{
		id: 2,
		value: 'K2(100)',
		label: 'K2(100)',
	},
	{
		id: 3,
		value: 'KOL(100)',
		label: 'KOL(100)',
	},
	{
		id: 4,
		value: 'Static(100)',
		label: 'Static(100)',
	},
]

export const SORTDATA=[
	{
		id:1,
		title:'Sort By'
	},
	{
		id:2,
		title:'Price'
	},
	{
		id:3,
		title:'Brand'
	}

]

export const FILTERDATA=[
	{
		id:1,
		title:'All Items'
	},
	{
		id:2,
		title:'Newest first'
	},
	{
		id:3,
		title:'Popular'
	}

]