import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({


    tabs: {
       borderTopRightRadius:'15px',
       backgroundColor:'#f3f0f0',
       overflow:'inherit !important'
    },

}));

export default useStyles;
