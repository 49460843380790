import React from "react";
import { Link } from "react-router-dom";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import CssBaseline from "@mui/material/CssBaseline";
// import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

// import ban from "../../ban.png";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";
import GridViewIcon from "@mui/icons-material/GridView";
import GppGoodIcon from "@mui/icons-material/GppGood";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import CollectionsIcon from "@mui/icons-material/Collections";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { connect } from "react-redux";
import * as TabAction from '../../redux/action/TabAction';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#dd72de",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginLeft: 400,
  paddingTop: 10,
  width: 450,
  letterSpacing: 8,
  borderRadius: 10,
}));

const Item1 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginLeft: 400,
  paddingTop: 10,
}));

function Profile() {
  //  Menu Function
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container fixed>
        <ImageList
          sx={{
            width: 1100,
            height: 250,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            marginLeft: 4,
          }}
          cols={1}
          rowHeight={164}
        >
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
        <h2>
          <img
            className="view_img_1"
            width="200"
            src="https://wallpaperaccess.com/full/329362.jpg"
            alt="image"
          />
        </h2>
        <h3 className="Icon_1">
          <MoreVertIcon sx={{ width: 40, height: 40 }} />
        </h3>
        <br />
        <h2 className="name">@Mibao</h2>
        <h2 className="flag">
          <img width="50" src='/assets/images/china.png' alt="flag" />
        </h2>
        <Stack direction="row" spacing={2}>
          <Item sx={{ backgroundColor: "#212330" }}>
            <img width="50" src="https://wallpaperaccess.com/full/329362.jpg" />{" "}
            <img width="50" src="https://wallpaperaccess.com/full/329362.jpg" />{" "}
            <img width="50" src="https://wallpaperaccess.com/full/329362.jpg" />{" "}
            <img width="50" src="https://wallpaperaccess.com/full/329362.jpg" />{" "}
            <img width="50" src="https://wallpaperaccess.com/full/329362.jpg" />{" "}
            <img width="50" src="https://wallpaperaccess.com/full/329362.jpg" />{" "}
            <img width="50" src="https://wallpaperaccess.com/full/329362.jpg" />{" "}
          </Item>
        </Stack>
        <br></br>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <Item1 sx={{ boxShadow: "none", fontSize: 16, color: "#212330" }}>
            <strong>83</strong>
            <br /> Subscribers
          </Item1>
          <Item1 sx={{ boxShadow: "none", fontSize: 16, color: "#212330" }}>
            <strong>25</strong> <br /> Followers
          </Item1>
          <Item1 sx={{ boxShadow: "none", fontSize: 16, color: "#212330" }}>
            <strong>16</strong> <br /> Following
          </Item1>
          <Item1 sx={{ boxShadow: "none", fontSize: 16, color: "#212330" }}>
            <strong>1K</strong> <br /> Knocks
          </Item1>
        </Stack>
        <br></br>
        <p className="para_1">
          Ut ut ante id nibh malesuada eleifend. Sed auctor nibh at nibh
          fringilla, at dignissim elit
          <br /> mollis. Vivamus consectetur, risus a gravida varius
        </p>
        <br></br>
        <Stack direction="row" spacing={2} className="change_color_button">
          <Button variant="contained" sx={{ backgroundColor: "#212330" }}>
            Subscribe
          </Button>
          <Button variant="contained" sx={{ backgroundColor: "#212330" }}>
            Follow
          </Button>
          <Button variant="contained" sx={{ backgroundColor: "#212330" }}>
            Message
          </Button>
          <Button variant="contained" sx={{ backgroundColor: "#212330" }}>
            Suggested
          </Button>
        </Stack>
        <br></br>
        <br></br>
        <Stack direction="row" spacing={2}>
          <Tooltip title="Post">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 60 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <GridViewIcon sx={{ width: 50, height: 50, color: "#212330" }} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <ListItemIcon>
                <GridViewIcon fontSize="small" />
              </ListItemIcon>
              <Link to="/story">Pictures</Link>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <VideoLibraryIcon fontSize="small" />
              </ListItemIcon>
              Reels
            </MenuItem>
          </Menu>

          <Tooltip title="NFT">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <GppGoodIcon sx={{ width: 50, height: 50, color: "#212330" }} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <ListItemIcon>
                <GridViewIcon fontSize="small" />
              </ListItemIcon>
              <Link to="/story">Pictures</Link>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <VideoLibraryIcon fontSize="small" />
              </ListItemIcon>
              Reels
            </MenuItem>
          </Menu>

          <Tooltip title="Premium">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <WorkspacePremiumIcon
                sx={{ width: 50, height: 50, color: "#212330" }}
              />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <ListItemIcon>
                <GridViewIcon fontSize="small" />
              </ListItemIcon>
              <Link to="/story">Pictures</Link>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <VideoLibraryIcon fontSize="small" />
              </ListItemIcon>
              Reels
            </MenuItem>
          </Menu>
        </Stack>
        <br></br> <br></br> <br></br>
        <Stack direction="row" spacing={2}>
          <Card sx={{ maxWidth: 400, borderRadius: 5 }}>
            <CardMedia
              component="img"
              height="400"
              // image="https://wallpaperaccess.com/full/329362.jpg"
              src="/assets/images/small.png"
              alt="green iguana"
            />
          </Card>
          <Card sx={{ maxWidth: 400, borderRadius: 5 }}>
            <CardMedia
              component="img"
              height="400"
              // image="https://wallpaperaccess.com/full/329362.jpg"
              src="/assets/images/small.png"
              alt="green iguana"
            />
          </Card>
          <Card sx={{ maxWidth: 400, borderRadius: 5 }}>
            <CardMedia
              component="img"
              height="400"
              // image="https://wallpaperaccess.com/full/329362.jpg"
              src="/assets/images/small.png"
              alt="green iguana"
            />
          </Card>
        </Stack>
        <br />
        <Stack direction="row" spacing={2}>
          <Card sx={{ maxWidth: 400, borderRadius: 5 }}>
            <CardMedia
              component="img"
              height="400"
              // image="https://wallpaperaccess.com/full/329362.jpg"
              src="/assets/images/small.png"
              alt="green iguana"
            />
          </Card>
          <Card sx={{ maxWidth: 400, borderRadius: 5 }}>
            <CardMedia
              component="img"
              height="400"
              // image="https://wallpaperaccess.com/full/329362.jpg"
              src="/assets/images/small.png"
              alt="green iguana"
            />
          </Card>
          <Card sx={{ maxWidth: 400, borderRadius: 5 }}>
            <CardMedia
              component="img"
              height="400"
              // image="https://wallpaperaccess.com/full/329362.jpg"
              src="/assets/images/small.png"
              alt="green iguana"
            />
          </Card>
        </Stack>
        <br />
        <Stack direction="row" spacing={2}>
          <Card sx={{ maxWidth: 400, borderRadius: 5 }}>
            <CardMedia
              component="img"
              height="400"
              // image="https://wallpaperaccess.com/full/329362.jpg"
              src="/assets/images/small.png"
              alt="green iguana"
            />
          </Card>
          <Card sx={{ maxWidth: 400, borderRadius: 5 }}>
            <CardMedia
              component="img"
              height="400"
              // image="https://wallpaperaccess.com/full/329362.jpg"
              src="/assets/images/small.png"
              alt="green iguana"
            />
          </Card>
          <Card sx={{ maxWidth: 400, borderRadius: 5 }}>
            <CardMedia
              component="img"
              height="400"
              // image="https://wallpaperaccess.com/full/329362.jpg"
              src="/assets/images/small.png"
              alt="green iguana"
            />
          </Card>
        </Stack>
        <br></br>
      </Container>
    </React.Fragment>
  );
}

const itemData = [
  {
    img: "https://wallpaperaccess.com/full/329362.jpg",
    // img: { ban },
    title: "Natural",
  },
];

const mapStateToProps = (state) => {
    return {
      selectedTab: state.TabReducer.selectedTab
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setSelectedTab: (token) =>
        dispatch(TabAction.setSelectedTab(token)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Profile);