import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import RedditIcon from "@mui/icons-material/Reddit";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { ReactComponent as LogoSvg } from '../assets/icons/K2_logo_v3.svg';
import { Typography } from "@mui/material";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const market = [
  { id: 1, name: "All NFT" },
  { id: 2, name: "Art" },
  { id: 3, name: "Collectibles" },
  { id: 4, name: "Collectibles" },
];

const account = [
  { id: 1, name: "Profile" },
  { id: 2, name: "Favorites" },
  { id: 3, name: "Watchlist" },
  { id: 4, name: "My Collections" },
  { id: 5, name: "Settings" },
];

function Footer() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container fixed sx={{pl:0,pr:0}}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Item
                sx={{
                  boxShadow: "none",
               
                  color: "#000",
                  textAlign: "left",
                }}
              >
                <h2 className="com_1">Stay Connected</h2>
                <p className="fi_para_1" style={{ textAlign: "left" }}>
                  Ut ut ante id nibh malesuada eleifend.Sed auctor nibh at nibh
                  fringilla,at dignissim elit mollis
                </p>
              </Item>

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} className="field_in">
                    <Item sx={{ boxShadow: "none", textAlign: "left" }}>
                      <Paper
                        component="form"
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          width: 370,
                          height: 48,
                          border: "2px solid #b7b7b7",
                          boxShadow: "none",
                        }}
                      >
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Your email address"
                          inputProps={{ "aria-label": "search google maps" }}
                        />
                      </Paper>
                    </Item>
                  </Grid>
                  <Grid item xs={3}>
                    <Item sx={{ boxShadow: "none" }}>
                      <Button
                        className="sign_1"
                        size="large"
                        variant="contained"
                      >
                        Sign up
                      </Button>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{ boxShadow: "none",  color: "#000" }}>
                <h2 className="com_1">Join the Community</h2>
              </Item>

              <Box
                className="socialicon_row"
                sx={{
                  flexGrow: 1,
                  maxWidth: 240,
                  marginLeft: 9,
                  marginTop: -3,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Item sx={{ boxShadow: "none", textAlign: "left" }}>
                      <FacebookRoundedIcon
                        style={{ color: "#000", fontSize: 34 }}
                      />
                    </Item>
                  </Grid>

                  <Grid item xs={3}>
                    <Item sx={{ boxShadow: "none" }}>
                      <InstagramIcon style={{ color: "#000", fontSize: 34 }} />
                    </Item>
                  </Grid>

                  <Grid item xs={3}>
                    <Item sx={{ boxShadow: "none" }}>
                      <TwitterIcon style={{ color: "#000", fontSize: 34 }} />
                    </Item>
                  </Grid>

                  <Grid item xs={3}>
                    <Item sx={{ boxShadow: "none" }}>
                      <RedditIcon style={{ color: "#000", fontSize: 34 }} />
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <br />
        <br />
        <Divider />

        <Grid container style={{ marginTop: 28 }}>
          <Grid item lg={4}>
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            // aria-label="menu"
            // className={classes.logo}
          >
            <LogoSvg /><Typography variant='h6' sx={{ fontWeight: 'bold' }}>&nbsp;&nbsp;KNOCK KNOCK LIVE</Typography>
          </IconButton>
            <br />
            <p
              style={{
                textAlign: "left",
                fontSize: 13,
                maxWidth: 248,
                fontWeight: "lighter",
              }}
            >
              Ut ut ante id nibh malesuada eleifend.Sed auctor nibh at nibh
              fringilla,dignissim elit mollis
            </p>
          </Grid>

          <Grid item lg={2}>
            <span className="sap_2" style={{marginTop:'5px',marginBottom:'15px',fontWeight:'bold'}}>Marketplace</span>
            <br /> <br />
            {market.map((data) => {
              return (
                <>
                  <h6 className="hed_1" style={{margin:'0px',marginTop:'2px'}}>{data.name}</h6>
                </>
              );
            })}
          </Grid>

          <Grid item lg={2}>
            <span className="sap_3" style={{marginTop:'5px',marginBottom:'15px',fontWeight:'bold'}}>My Account</span>
            <br /> <br />
            {account.map((data) => {
              return (
                <>
                  <h6 className="hed_1" style={{margin:'0px',marginTop:'2px'}}>{data.name}</h6>
                </>
              );
            })}
          </Grid>

          <Grid item lg={2}>
            <span className="sap_4"  style={{marginTop:'5px',marginBottom:'15px',fontWeight:'bold'}}>Resources</span>
          </Grid>

          <Grid item lg={2}>
            <span className="sap_5"  style={{marginTop:'5px',marginBottom:'15px',fontWeight:'bold'}}>Company</span>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Footer;
