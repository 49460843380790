import { handleActions } from 'redux-actions';

const TabReducer = {
  selectedTab: 0,
};

const reducer = handleActions(
  {
    TAB_SUCCESS: (state, selectedTab) => {
      return {
        ...state,
        selectedTab: selectedTab.payload,
      };
    },
  },
  TabReducer
);
export default reducer;
