import React from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Admin_Login from "../components/admin/adminLogin";
import Dynamic from "../components/admin/adminVerifyPage";
import Dashboard from "../components/Dashboard/dashboard";
import Home from "../components/usercomponent";

export default function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Admin_Login />} />
        <Route path="/adminVerify" element={<Dynamic />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <h1>Page Not Found</h1>
              <Link to="/">
                <p>Go to Home</p>
              </Link>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
