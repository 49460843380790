import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserHeader from "../navigation/userheader";
// require('dotenv').config();
// const { Backend_url } = process.env.Backend_url;
function Admin_Login() {
  const history = useNavigate();
  const location = useLocation();
  const success = (value) => {
    toast.success(value, { theme: "colored" });
  };
  const faild = (value) => {
    toast.error(value, { theme: "colored" });
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Email must be valid email")
        .required("Email is required"),
      password: yup
        .string()
        .required("password is required")
        .min(8, "Password is too short - should be 8 chars minimum."),
    }),
    onSubmit: (userinputdata) => {
      console.log(userinputdata);
      let logindate = {
        email: userinputdata.email,
        password: userinputdata.password,
      };

      axios
        .post(
          `${process.env.REACT_APP_Backend_url}/auth/Admin-Login`,
          logindate
        )
        .then((res) => {
          // console.log( JSON.parse(res.data.userDetails.UserDetails.email));

          console.log(res.data);
          if (res.data.message === "Login done successfully") {
            success(res.data.message);

            localStorage.setItem(
              "token",
              JSON.stringify(res.data?.data?.token)
            );
            localStorage.setItem(
              "adminDetails",
              JSON.stringify(res.data.data?.userDetails)
            );
            localStorage.setItem("is-admin-loged", true);

            setTimeout(function () {
              history("/AdminVerify");
            }, 2000);
          } else {
            faild(res.data.message);
          }
        });
    },
  });
  useEffect(() => {
    var is_admin_loged = localStorage.getItem("is-admin-loged");

    if (is_admin_loged) {
      history("/AdminVerify");
    }
  });

  const data = () => {
    history.push("/forgotpassword");
  };
  return (
    <center>
      <ToastContainer />
      <UserHeader />
      <div className="container">
        <br />
        <div className="row col-lg-10 col-md-10 col-sm-10 mx-auto">
          <div className=" col-md-6 col-md-offset-3 mx-auto">
            <form onSubmit={formik.handleSubmit}>
              <h3>Admin Log In</h3>
              <div className="mb-3 labels">
                <label htmlFor="left-label" className="text-left">
                  Email{" "}
                </label>
                <input
                  onChange={formik.handleChange}
                  type="email"
                  name="email"
                  className="form-control in1"
                  placeholder="Enter email"
                />
                {formik.errors.email ? (
                  <div className="text-danger">{formik.errors.email} </div>
                ) : null}
              </div>
              <div className="mb-3 labels">
                <label htmlFor="left-label" className="text-left">
                  {" "}
                  Password
                </label>
                <input
                  onChange={formik.handleChange}
                  type="password"
                  name="password"
                  className="form-control in1"
                  placeholder="Enter password"
                />
                {formik.errors.password ? (
                  <div className="text-danger">{formik.errors.password} </div>
                ) : null}
              </div>
              <div className="mb-3"></div>
              <div className="d-grid login">
                <button type="submit" className="btn btn-primary loginbtn">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </center>
  );
}

export default Admin_Login;
